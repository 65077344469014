import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import AppointmentCampaignApiController from "../../services/appointmentcampaign.service";
import HandleError from "../HandleError";
import HandleResponse from "../HandleResponse";
export const usersAdapter = createEntityAdapter();

export const AppointmentCampaignStoreApi = createAsyncThunk("appointmentcampaign/create", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.create(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "create"))
      .catch((error) => HandleError(thunkAPI, error, "create"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const AppointmentCampaignUpdateApi = createAsyncThunk("appointmentcampaign/update", async (formvalues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.update(formvalues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "update"))
      .catch((error) => HandleError(thunkAPI, error, "update"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue({ status: error.response.status, message: message });
  }
});

export const AppointmentCampaignListViewApi = createAsyncThunk("appointmentcampaign/gridview", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "gridview"))
      .catch((error) => HandleError(thunkAPI, error, "gridview"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AppointmentCampaignOptions = createAsyncThunk("appointmentcampaign/AppointmentCampaignOptions", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "AppointmentCampaignOptions"))
      .catch((error) => HandleError(thunkAPI, error, "AppointmentCampaignOptions"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AppointmentCampaignDetailApi = createAsyncThunk("appointmentcampaign/detail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.view(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "detail"))
      .catch((error) => HandleError(thunkAPI, error, "detail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AppointmentCampaignDeleteApi = createAsyncThunk("appointmentcampaign/delete", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.deleted(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "delete"))
      .catch((error) => HandleError(thunkAPI, error, "delete"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddonservicesApi = createAsyncThunk("appointmentcampaign/Addonservices", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.addonservices(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Addonservices"))
      .catch((error) => HandleError(thunkAPI, error, "Addonservices"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AddonproductsApi = createAsyncThunk("appointmentcampaign/Addonproducts", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.addonproducts(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "Addonproducts"))
      .catch((error) => HandleError(thunkAPI, error, "Addonproducts"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const AppointmentCampaignSendEmailApi = createAsyncThunk("appointmentcampaign/sendemail", async (formValues, thunkAPI) => {
  try {
    const resposedata = await AppointmentCampaignApiController.sendemail(formValues, thunkAPI)
      .then((response) => HandleResponse(thunkAPI, response, "sendemail"))
      .catch((error) => HandleError(thunkAPI, error, "sendemail"));
    return resposedata;
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const initialState = {
  isAppointmentType: "",
  isOpenedTypeForm: "",
  isOpenedAddForm: "",
  isOpenedEditForm: "",
  isOpenedSendEmailForm: "",
  isOpenedDetailModal: "",
  isListView: [],
  isSuggetionListView: [],
  isDetailData: "",
  isSearchList: "",
  isSearchName: "",
  isEmailPreview: "",
  isSmsPreview: "",
  isCategoryOption: [],
  isServiceModal: "",
  isAddonServices: [],
  isAddonServicesSelected: [],
  isProductModal: "",
  isAddonProducts: [],
  isAddonProductsSelected: [],
  isAppointmentCampaignSelectedType: "",
  isAppointmentSendEmail: "",
  isOpenedSendEmailObj: "",
  isSMScharacterLimits: 0,
  appointmentValuesSave: "",
  isApiStatus: {
    AppointmentCampaignStoreApi: "",
    AppointmentCampaignUpdateApi: "",
    AppointmentCampaignListViewApi: "",
    AppointmentCampaignSuggetionListApi: "",
    AppointmentCampaignDetailApi: "",
    AppointmentCampaignDeleteApi: "",
    AppointmentCampaignOptions: "",
    AddonservicesApi: "",
    AppointmentCampaignSendEmailApi: "",

  },
};

const AppointmentCampaignSlice = createSlice({
  name: "appointmentcampaign",
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
      state.isAddonServicesSelected = [];
      state.isAddonProductsSelected = [];
    },
    AppointmentCampaignType: (state, action) => {
      state.isAppointmentType = action.payload;
    },
    AppointmentCampaignSelectedType: (state, action) => {
      state.isAppointmentCampaignSelectedType = action.payload;
    },
    AppointmentCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = "";
      state.isOpenedTypeForm = action.payload;
    },
    AddAppointmentCampaignFormModal: (state, action) => {
      state.isOpenedEditForm = "";
      state.isOpenedAddForm = action.payload;
    },
    EditAppointmentCampaignFormModal: (state, action) => {
      state.isOpenedAddForm = "";
      state.isOpenedEditForm = action.payload;
    },
    ServiceModal: (state, action) => {
      state.isServiceModal = action.payload;
    },
    ProductModal: (state, action) => {
      state.isProductModal = action.payload;
    },
    SetEmailPreview: (state, action) => {
      state.isEmailPreview = action.payload;
    },
    SetSmsPreview: (state, action) => {
      state.isSmsPreview = action.payload;
    },
    AddonserviceAction: (state, action) => {
      state.isAddonServicesSelected = action.payload;
      // state.isAddonServices = action.payload;
    },
    AddonserviceActionDelete: (state, action) => {
      state.isAddonServicesSelected = state.isAddonServicesSelected ? state.isAddonServicesSelected.filter((item) => item != action.payload) : [];
      // state.isAddonServices = action.payload;
    },
    AddonproductAction: (state, action) => {
      state.isAddonProductsSelected = action.payload;
    },
    AddonproductActionDelete: (state, action) => {
      state.isAddonProductsSelected = state.isAddonProductsSelected ? state.isAddonProductsSelected.filter((item) => item != action.payload) : [];
    },
    SendEmailAppointmentCampaignFormModal: (state, action) => {
      state.isOpenedSendEmailForm = action.payload;
    },
    SendEmailAppointmentCampaignObjModal: (state, action) => {
      state.isOpenedSendEmailObj = action.payload;
    },
    SMScharacterLimitSet: (state, action) => {
      state.isSMScharacterLimits = action.payload;
    },
    AppointmentValuesSave: (state, action) => {
      state.appointmentValuesSave = action.payload;
    },
    resetFormState: (state) => {
      state.isAddonServicesSelected = [];
      state.isAddonProductsSelected = [];
      state.isEmailPreview = "";
      state.isSmsPreview = "";
      state.isSMScharacterLimits = 0;
      state.appointmentValuesSave = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AppointmentCampaignStoreApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignStoreApi: "loading" };
      })
      .addCase(AppointmentCampaignStoreApi.fulfilled, (state, action) => {
        if (state.isListView && state.isListView.data) {
          state.isListView.data = [action.payload, ...state.isListView.data];
        } else {
          state.isListView = { data: [action.payload] };
        }
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignStoreApi: "succeeded" };
      })
      .addCase(AppointmentCampaignStoreApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignStoreApi: "failed" };
      })
      .addCase(AppointmentCampaignUpdateApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignUpdateApi: "loading" };
      })
      .addCase(AppointmentCampaignUpdateApi.fulfilled, (state, action) => {
        const { id, ...changes } = action.payload;
        const existingData = state.isListView.data.find((event) => event.id === id);
        if (existingData) {
          Object.keys(changes).map((keyName) => {
            existingData[keyName] = changes[keyName];
          });
        }
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignUpdateApi: "succeeded" };
      })
      .addCase(AppointmentCampaignUpdateApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignUpdateApi: "failed" };
      })
      .addCase(AppointmentCampaignListViewApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignListViewApi: "loading" };
      })
      .addCase(AppointmentCampaignListViewApi.fulfilled, (state, action) => {
        let old_current_page = state.isListView.current_page || "";
        let new_current_page = action.payload.current_page || "";
        let viewdata = state.isListView && state.isListView.data;
        let newviewdata = action.payload && action.payload.data;
        state.isListView = action.payload;
        if (old_current_page && new_current_page && old_current_page < new_current_page && old_current_page !== new_current_page) {
          viewdata && newviewdata ? (state.isListView.data = [...viewdata, ...newviewdata]) : action.payload;
        }
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignListViewApi: "succeeded" };
      })
      .addCase(AppointmentCampaignListViewApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignListViewApi: "failed" };
        state.isListView = [];
      })
      .addCase(AppointmentCampaignDetailApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignDetailApi: "loading" };
      })
      .addCase(AppointmentCampaignDetailApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignDetailApi: "succeeded" };
        state.isDetailData = action.payload;
      })
      .addCase(AppointmentCampaignDetailApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignDetailApi: "failed" };
        state.isDetailData = "";
      })
      .addCase(AppointmentCampaignDeleteApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignDeleteApi: "loading" };
      })
      .addCase(AppointmentCampaignDeleteApi.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignDeleteApi: "succeeded" };
        state.isListView.data = state.isListView.data ? state.isListView.data.filter((item) => item.id !== id) : [];
      })
      .addCase(AppointmentCampaignDeleteApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AppointmentCampaignDeleteApi: "failed" };
      })
      .addCase(AddonservicesApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AddonservicesApi: "loading" };
      })
      .addCase(AddonservicesApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, AddonservicesApi: "succeeded" };
        state.isAddonServices = action.payload;
      })
      .addCase(AddonservicesApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AddonservicesApi: "failed" };
        state.isAddonServices = [];
      })
      .addCase(AddonproductsApi.pending, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AddonproductsApi: "loading" };
      })
      .addCase(AddonproductsApi.fulfilled, (state, action) => {
        state.isApiStatus = { ...state.isApiStatus, AddonproductsApi: "succeeded" };
        state.isAddonProducts = action.payload;
      })
      .addCase(AddonproductsApi.rejected, (state) => {
        state.isApiStatus = { ...state.isApiStatus, AddonproductsApi: "failed" };
        state.isAddonProducts = [];
      });
  },
});
// Action creators are generated for each case reducer function
export const { reset, AppointmentCampaignType,resetFormState, AppointmentCampaignFormModal, AddAppointmentCampaignFormModal, EditAppointmentCampaignFormModal, SetEmailPreview, SetSmsPreview, ServiceModal, AddonserviceAction, AddonserviceActionDelete, AppointmentCampaignSelectedType, ProductModal, AddonproductAction, AddonproductActionDelete, SendEmailAppointmentCampaignFormModal, SendEmailAppointmentCampaignObjModal, SMScharacterLimitSet, AppointmentValuesSave } = AppointmentCampaignSlice.actions;
export default AppointmentCampaignSlice.reducer;